<template>
  <div class="col-12">
    <div class="card">
        <div class="card-header">
            <h5>
                المشرفين 
                <button class="btn btn-primary" style="padding: 0px; width: 30px; height: 30px; border-radius: 50%" v-b-modal.create>
                    <i class="fa fa-plus"></i>
                </button>
            </h5>
        </div>
        <div class="card-body">
            <div class="col-12 table-responsive">
                <table class="table table-hover table-bordered table-sm">
                    <thead>
                        <th>
                            الاسم
                        </th>
                        <th>
                            اسم المستخدم
                        </th>
                        <th>
                            تاريخ الاضافة
                        </th>
                        <th>
                            الصلاحيات
                        </th>
                        <th>
                            خيارات
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="admin in admins" :key="admin._id">
                            <td>
                                {{ admin.name }}
                            </td>
                            <td>
                                {{ admin.username }}
                            </td>
                            <td>
                                {{ admin.joindate }}
                            </td>
                            <td>
                                {{ admin.pers.includes("*") ? 'كل الصلاحيات' : admin.pers.length + ' صلاحية' }}
                            </td>
                            <td>
                                <button class="btn btn-sm btn-primary" style="border-radius: 0px" v-b-modal.edit @click="current = admin">
                                    <i class="fa fa-edit"></i>
                                    تعديل
                                </button>
                                <button class="btn btn-sm btn-danger" style="border-radius: 0px" @click="deleteAdmin(admin._id)">
                                    <i class="fa fa-trash"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <b-modal id="create" title="اضافة مشرف" hide-footer size="log">
        <div class="form-group">
          <label for="">الاسم</label>
          <input type="text"
            class="form-control" v-model="create.name">
        </div>
        <div class="form-group">
          <label for="">اسم المستخدم (لتسجيل الدخول)</label>
          <input type="text"
            class="form-control" v-model="create.username">
        </div>
        <div class="form-group">
          <label for="">كلمة المرور (لتسجيل الدخول)</label>
          <input type="text"
            class="form-control" v-model="create.password">
        </div>
        <div class="form-group">
          <label for="">الجوال</label>
          <input type="text"
            class="form-control" v-model="create.phone">
        </div>
        <div class="form-check">
          <label class="form-check-label">
            <input type="checkbox" class="form-check-input" v-model="create.pers" value="*">
            اعطاء كامل الصلاحيات
          </label>
          <br><br>
        </div>
        <div class="card card-body" v-if="!create.pers.includes('*')">
            <div class="" v-for="per in pers" :key="per.title">
                <strong>
                    <i class="fa fa-arrow-left"></i>
                    {{ per.title }}
                    <br><br>
                </strong>
                <template v-for="(item, i) in per.items">
                    <div class="form-check" v-if="create.pers.includes(per.code) || i == 0 || per.code == ''" :key="item.code">
                        <label class="form-check-label">
                            <span v-if="!item.code" class="text-muted">...........</span>
                            <input type="checkbox" class="form-check-input" v-model="create.pers" :value="item.code" v-if="item.code">
                            {{ item.title }}
                        </label>
                    <br>
                    </div>
                </template>
                <hr>
            </div>
        </div>
        <div class="col-12 text-center">
            <button class="btn btn-primary" @click="addNow()">
                اضافة المشرف
            </button>
        </div>
    </b-modal>
    <b-modal id="edit" title="تعديل المشرف" hide-footer size="log">
        <template v-if="current._id">
            <div class="form-group">
                <label for="">الاسم</label>
                <input type="text"
                    class="form-control" v-model="current.name">
                </div>
                <div class="form-group">
                <label for="">اسم المستخدم (لتسجيل الدخول)</label>
                <input type="text"
                    class="form-control" readonly v-model="current.username">
                </div>
                <div class="form-group">
                <label for="">كلمة المرور (لتسجيل الدخول)</label>
                <input type="text"
                    class="form-control" v-model="current.password">
                </div>
                <div class="form-group">
                <label for="">الجوال</label>
                <input type="text"
                    class="form-control" v-model="current.phone">
                </div>
                <div class="form-check">
                <label class="form-check-label">
                    <input type="checkbox" class="form-check-input" v-model="current.pers" value="*">
                    اعطاء كامل الصلاحيات
                </label>
                <br><br>
                </div>
                <div class="card card-body" v-if="!current.pers.includes('*')">
                    <div class="" v-for="per in pers" :key="per.title">
                        <strong>
                            <i class="fa fa-arrow-left"></i>
                            {{ per.title }}
                            <br><br>
                        </strong>
                        <template v-for="(item, i) in per.items">
                            <div class="form-check" v-if="current.pers.includes(per.code) || i == 0 || per.code == ''" :key="item.code">
                                <label class="form-check-label">
                                    <span v-if="!item.code" class="text-muted">...........</span>
                                    <input type="checkbox" class="form-check-input" v-model="current.pers" :value="item.code" v-if="item.code">
                                    {{ item.title }}
                                </label>
                                <br>
                            </div>
                        </template>
                        <hr>
                    </div>
                </div>
                <div class="col-12 text-center">
                    <button class="btn btn-primary" @click="editNow()">
                        تعديل المشرف
                    </button>
                </div>
        </template>
    </b-modal>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { VBModal, BModal, BFormInput, BDropdown, BDropdownItem } from "bootstrap-vue";
export default {
    components: {
        // Layouts
        BDropdown, BDropdownItem,
        BModal,BFormInput},
    directives: {
        Ripple,
        "b-modal": VBModal,
    },
    data(){
        return {
            services: [],
            classes: [],
            user: JSON.parse(localStorage.getItem('user')),
            admins: [],
            current: {pers: []},
            create: {pers: []},
            pers: [
                {
                    title: "صلاحيات عامة",
                    code: "",
                    items: [
                        {
                            title: "الاشتراك في الخدمات",
                            code: "subscribe"
                        },
                        {
                            title: "اجهزة البصمة",
                            code: "devices"
                        },
                        {
                            title: "ارشيف الرسائل",
                            code: "archive"
                        },
                        {
                            title: "اعدادات الحساب",
                            code: "settings"
                        },
                        {
                            title: "اعدادات تطبيق الجوال",
                            code: "app-settings"
                        }
                    ]
                }
            ]
        }
    },
    created(){
        if(!checkPer("admins")){
            this.$router.push('/NotPermitted')
            return false;
        }
        var g = this;
        g.getAdmins();
        $.post(api + '/user/students/classes', {
            jwt: this.user.jwt
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                g.classes = r.response
                g.pers.push({
                    title: "الصفوف المسموح بها",
                    code: "",
                    items: g.classes.map(function(x){
                        return {
                            title: x.title,
                            code: x.title
                        }
                    })
                })
            }
        }).fail(function(){
        })
        $.post(api + '/user/subscribe/subscribes', {
            jwt: this.user.jwt
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                g.services = r.response;
                g.services.forEach(service => {
                    var arr = [];
                    arr.push({
                        title: "تفعيل خدمة '"+service.title+"' لهذا المشرف",
                        code: service.service
                    })
                    arr.push({})
                    //g.create.pers.push(service.service)
                    if(service.service == 'students'){
                        arr = arr.concat([
                            {
                                title: "استعراض الاحصائيات",
                                code: "students|stats"
                            },
                            {},
                            {
                                title: "اضافة الطلاب",
                                code: "students|students|add"
                            },
                            {
                                title: "استعراض الطلاب",
                                code: "students|students|view"
                            },
                            {
                                title: "تعديل الطلاب",
                                code: "students|students|edit"
                            },
                            {
                                title: "حذف الطلاب",
                                code: "students|students|delete"
                            },
                            {
                                title: "نقل الطلاب",
                                code: "students|students|move"
                            },
                            {
                                title: "ارسال رسائل للطلاب",
                                code: "students|students|send"
                            },
                            {
                                title: "تقييمات الطلاب",
                                code: "students|rates"
                            },
                            {
                                title: "الملاحظات الصحية",
                                code: "students|health"
                            },
                            {},
                            {
                                title: "تحضير يدوي",
                                code: "students|scan|manual"
                            },
                            {
                                title: "تحضير بالباركود",
                                code: "students|scan|barcode"
                            },
                            {
                                title: "تحضير جماعي",
                                code: "students|scan|bulk"
                            },
                            {
                                title: "شاشة الاستئذان",
                                code: "students|scan|endex"
                            },
                            {
                                title: "شاشة الانصرافات",
                                code: "students|scan|ends"
                            },
                            {
                                title: "الملاحظات عند الدخول",
                                code: "students|scan|ends"
                            },
                            {
                                title: "تحضير من الجوال",
                                code: "students|scan|phone"
                            },
                            {},
                            {
                                title: "تقارير الطلاب",
                                code: "students|reports"
                            },
                            {
                                title: "تقرير الحضور اليومي",
                                code: "students|reports|daily"
                            },
                            {
                                title: "تقرير الحضور لفترة",
                                code: "students|reports|between"
                            },
                            {
                                title: "تحضير فترة ماضية",
                                code: "students|reports|attend-before"
                            },
                            {},
                            {
                                title: "ارشيف الطلاب",
                                code: "students|archive"
                            },
                            {
                                title: "الرد على الغياب",
                                code: "students|reasons"
                            },
                            {
                                title: "المحول للوكيل",
                                code: "forwards"
                            },
                            {
                                title: "المحول للموجه",
                                code: "students|forwards2"
                            },
                            {
                                title: "المخالفات السلوكية",
                                code: "students|bis"
                            },
                            {
                                title: "المواقف الومية والزيارات",
                                code: "students|breaks-visits"
                            },
                            {
                                title: "اجراءات الغياب بدون عذر",
                                code: "students|alerts"
                            },
                            {
                                title: "شهادات الشكر والتقدير",
                                code: "students|certs"
                            },
                            {
                                title: "ساعات التطوع",
                                code: "students|hours"
                            },
                            {},
                            {
                                title: "اعدادات الحضور والارسال",
                                code: "students|settings"
                            }
                        ])
                    }
                    if(service.service == 'teachers'){
                        arr = arr.concat([
                            {
                                title: "استعراض الاحصائيات",
                                code: "teachers|stats"
                            },
                            {},
                            {
                                title: "تحضير المعلمين",
                                code: "teachers|scan"
                            },
                            {
                                title: "تحضير جماعي",
                                code: "teachers|scan|bulk"
                            },
                            {
                                title: "تحضير يدوي",
                                code: "teachers|scan|manual"
                            },
                            {
                                title: "تحضير بالباركود",
                                code: "teachers|scan|barcode"
                            },
                            {},
                            {
                                title: "استعراض المعلمين",
                                code: "teachers|view"
                            },
                            {
                                title: "اضافة المعلمين",
                                code: "teachers|add"
                            },
                            {},
                            {
                                title: "تقرير الحضور اليومي",
                                code: "teachers|reports|daily"
                            },
                            {
                                title: "تقرير الحضور لفترة",
                                code: "teachers|reports|between"
                            },
                            {
                                title: "المناوبة ",
                                code: "teachers|shifts"
                            },
                            {
                                title: " الاشراف",
                                code: "teachers|shifts2"
                            },
                            {
                                title: "التعاميم",
                                code: "teachers|mails"
                            },
                            {
                                title: "الاعدادات",
                                code: "teachers|settings"
                            },
                            {
                                title: "ارشيف الرسائل",
                                code: "teachers|archive"
                            },
                        ])
                    }
                    g.pers.push({
                        title: service.title,
                        code: service.service,
                        items: arr
                    })
                });
            }
        }).fail(function(){
            g.loading = false
        })
    },
    methods: {
        getAdmins(){
            var g = this;
            $.post(api + '/user/admins/list', {
                jwt: g.user.jwt
            }).then(function(r){
                g.admins = r.response
            })
        },
        deleteAdmin(id){
            if(confirm("متأكد من حظف المشرف؟")){
                var g = this;
                $.post(api + '/user/admins/delete', {
                    jwt: g.user.jwt,
                    id: id
                }).then(function(r){
                    alert("تم بنجاح", 100)
                    g.getAdmins()
                })
            }
        },
        addNow(){
            var g = this;
            $.post(api + '/user/admins/add', {
                jwt: g.user.jwt,
                name: g.create.name,
                phone: g.create.phone,
                username: g.create.username,
                password: g.create.password,
                pers: g.create.pers.join(',')
            }).then(function(r){
                if(r.status == 100){
                    alert("تم بنجاح", 100);
                    g.getAdmins()
                }else{
                    alert(r.response, 200)
                }
            }).fail(function(){
                alert("حدث خطأ في الاتصال")
            })
        },
        editNow(){
            var g = this;
            $.post(api + '/user/admins/edit', {
                jwt: g.user.jwt,
                id: g.current._id,
                name: g.current.name,
                phone: g.current.phone,
                password: g.current.password,
                pers: g.current.pers.join(',')
            }).then(function(r){
                if(r.status == 100){
                    alert("تم بنجاح", 100);
                    g.getAdmins()
                }else{
                    alert(r.response, 200)
                }
            }).fail(function(){
                alert("حدث خطأ في الاتصال")
            })
        }
    }
}
</script>

<style>

</style>